<template>
  <div>
    <document-selected-filter
      class="frontend"
      :selected-filters="selectedFilters"
      @deleteFilter="deleteFilter"
    />
  </div>
</template>

<script>
import DocumentSelectedFilter from '@/components/DocumentSelectedFilter';

export default {
  name: 'UserSelectedFilter',
  components: {
    DocumentSelectedFilter,
  },
  props: {
    selectedFilters: {
      type: Object,
      required: true,
    },
  },
  methods: {
    deleteFilter(filter, filterKey) {
      this.$emit('deleteFilter', filter, filterKey);
    },
  },
};
</script>

<style scoped></style>
