<template>
  <div>
    <document-filter
      ref="userDocumentFilterModal"
      :checked-filters="checkedFilters"
      :filters="filters"
      class="frontend"
    >
      <template #documentFilterTitle>
        <h3>{{ $t('document.filters.user.title') }}</h3>
      </template>

      <template #documentFilterBody>
        <div class="flex items-center">
          <date-filter v-model="checkedFilters.date" class="mr-28" />

          <state-filter v-model="checkedFilters.state" />
        </div>

        <document-type-of-law-filter
          v-model="checkedFilters.type_of_law"
          class="mt-8"
        />

        <div class="flex items-center justify-between">
          <document-origin-of-law-filter
            v-model="checkedFilters.origin_of_law"
            class="mt-8 w-1/2"
          />

          <category-filter
            v-model="checkedFilters.categories"
            class="mt-8 w-1/2"
          />
        </div>
      </template>

      <template #documentFilterFooter>
        <div class="flex justify-between">
          <button class="btn btn--secondary" @click="resetFilters">
            {{ $t('document.filters.resetFilters') }}
          </button>
          <button class="btn btn--primary" @click="filter">
            {{ $t('document.filters.filter') }}
          </button>
        </div>
      </template>
    </document-filter>
  </div>
</template>

<script>
import DocumentFilter from '@/components/DocumentFilter';
import DateFilter from '@/components/user/searchResult/filters/DateFilter';
import StateFilter from '@/components/user/searchResult/filters/StateFilter';
import DocumentTypeOfLawFilter from '@/components/admin/list/DocumentTypeOfLawFilter';
import DocumentOriginOfLawFilter from '@/components/admin/list/DocumentOriginOfLawFilter';
import CategoryFilter from '@/components/user/searchResult/filters/CategoryFilter';
import filterMixin from '@/components/filterMixin';
import UserEventBus from '@/helpers/UserEventBus';
import { mapState } from 'vuex';

export default {
  name: 'UserDocumentFilter',
  components: {
    DocumentFilter,
    DateFilter,
    StateFilter,
    DocumentTypeOfLawFilter,
    DocumentOriginOfLawFilter,
    CategoryFilter,
  },
  mixins: [filterMixin],
  data() {
    return {
      filters: {},
      checkedFilters: {},
    };
  },
  computed: {
    ...mapState('Search', ['selectedFilters']),
  },
  watch: {
    selectedFilters: {
      immediate: true,
      handler(newValue) {
        this.checkedFilters = newValue;
        this.filters = Object.assign({}, this.checkedFilters);
      },
    },
  },
  mounted() {
    UserEventBus.$on('deleteFilter', this.deleteFilter);
    UserEventBus.$on('resetFiltersOnPageSwitch', this.resetFiltersOnPageSwitch);
    UserEventBus.$on('setDefaultFilter', this.setDefaultFilter);
  },
  beforeDestroy() {
    UserEventBus.$off('deleteFilter', this.deleteFilter);
    UserEventBus.$off(
      'resetFiltersOnPageSwitch',
      this.resetFiltersOnPageSwitch
    );
    UserEventBus.$off('setDefaultFilter', this.setDefaultFilter);
  },
  methods: {
    async filter() {
      this.filters = Object.assign({}, this.checkedFilters);
      const cleanedFilters = this.cleanFilters();
      this.$emit('filterResults', cleanedFilters, this.filters);
      this.$refs.userDocumentFilterModal.close(true);
    },
    resetFilters() {
      this.filters = {};
      this.checkedFilters = {};
      this.resetActiveStates();
      this.filter();
    },
    deleteFilter(filter, filterKey) {
      Object.keys(this.checkedFilters).forEach((key) => {
        if (key === filterKey) {
          // Delete filter in array
          if (this.checkedFilters[key].length > 0) {
            this.checkedFilters[key].splice(
              this.checkedFilters[key].indexOf(filter),
              1
            );
            // If no filter values are left for filter key, delete it.
            if (this.checkedFilters[key].length === 0) {
              this.$delete(this.checkedFilters, key);
            }
          } else {
            // Delete date filter
            this.$delete(this.checkedFilters, key);
          }
        }
      });
      this.filter();
    },
    resetFiltersOnPageSwitch(filters) {
      this.checkedFilters = filters;
      this.filters = filters;
    },
    setDefaultFilter() {
      this.$set(this.checkedFilters, 'state', [
        {
          id: 'is_in_order',
          name: this.$t('document.states.inOrder'),
          type: 'state',
        },
      ]);
      this.filter();
    },
  },
};
</script>

<style scoped></style>
