<template>
  <div>
    <div class="flex flex-col">
      <label class="text-lg font-sansbold">
        {{ $t('document.labels.user.validAt') }}
      </label>
      <date-picker
        v-model="dateAt"
        format="DD.MM.YYYY"
        class="pb-2 mt-2 date-picker"
        :placeholder="$i18n.t('document.placeholders.validAt')"
        :append-to-body="false"
      />
    </div>
  </div>
</template>

<script>
import DatePicker from '@/components/Datepicker.vue';
import 'vue2-datepicker/index.css';

export default {
  name: 'DateFilter',
  components: {
    DatePicker,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
    dateAt: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit('input', newVal);
      },
    },
  },
};
</script>

<style lang="postcss" scoped>
.date-picker {
  width: 210px !important;
}

.mx-input {
  height: 40px !important;
}
</style>
